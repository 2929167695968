import { PaymentStatus } from '@/controllers/graphql/generated';
import { ROUTES } from '@/controllers/router/router.contants';

export const freeSubscriptionType = 'free';

export const FAIL_PAYMENT_STATUSES = [
  PaymentStatus.Failed,
  PaymentStatus.Declined,
];

export const TEMPORARY_EMAIL_PATTERN = /\b[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89ab][a-f0-9]{3}-[a-f0-9]{12}@temporary\.com\b/;

export const ACTIVATION_QUIZ_ROUTES = [
  ROUTES.jsActivationQuiz,
  ROUTES.pythonActivationQuiz,
];
