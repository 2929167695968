import { errorHandler } from '@/core/ErrorHandler';
import { logger } from '@/core/Logger';

export const setClaritySessionTags = (
  tags: Record<string, any>,
) => {
  // eslint-disable-next-line @mate-academy/frontend/restrict-window-usage
  if (!window.clarity) {
    return;
  }

  try {
    Object.entries(tags).forEach(([key, value]) => {
      // The tag and its value can't be longer than 255 characters.
      const preparedKey = String(key).slice(0, 255);
      const preparedValue = String(value).slice(0, 255);

      // eslint-disable-next-line @mate-academy/frontend/restrict-window-usage
      window.clarity?.('set', preparedKey, preparedValue);
    });
  } catch (error) {
    errorHandler.captureException(error as Error, {
      logger: logger.child('setClaritySessionTags'),
      logMessage: 'Failed to set Clarity session tags',
      fields: {
        tags,
      },
    });
  }
};
