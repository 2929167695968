import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import { cn } from '@/lib/classNames';
import { typography } from '@/components/ui/typography';
import { Loader } from '@/components/ui/Loader';
import { Button } from '@/components/ui/Button';
import { IconReset } from '@/components/ui/icons/IconReset';
import { LogoSquare } from '@/components/common/Logo';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { nullFunction } from '@/lib/helpers/functional';
import styles from './ContentPreloader.module.scss';

interface Props {
  text: string;
  feedbackButton?: React.ReactComponentElement<any>;
  onReload?: () => void;
  paddingless?: boolean;
}

export const ContentPreloader: FC<Props> = (props) => {
  const {
    text,
    feedbackButton = null,
    onReload = nullFunction,
    paddingless = false,
  } = props;

  const { t } = useTranslation([I18N_CODES.common]);

  const [isLoadingTooLong, setIsLoadingTooLong] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoadingTooLong(true);
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const reload = useCallback(() => {
    onReload();
    window.location.reload();
  }, [onReload]);

  return (
    <div
      className={cn(
        styles.wrapper,
        'content-preloader-wrapper',
        'grid-container',
        { 'pt-128 pb-32': !paddingless },
      )}
      data-qa='topic-page-preloader'
    >
      <div className="flex-container align-center mb-24">
        <LogoSquare className={styles.logo} />
      </div>

      <div className={cn(typography.platformTextMain, 'text-center mb-48')}>
        {text}
        {isLoadingTooLong && (
          <div className={cn(typography.platformTextMain, 'mr-8 grid-container align-center-middle mt-16')}>
            <p>
              {t(`${I18N_CODES.common}:content-preloader_long_loading_text`)}
            </p>
            <p>
              {t(`${I18N_CODES.common}:content-preloader_long_loading_suggestion`)}
            </p>
          </div>
        )}
      </div>

      <div className="flex-container align-center-middle mb-48">
        <Loader
          className={cn(styles.loaderWrapper, 'mr-8')}
          spinnerClassName={styles.loaderSpinner}
          loading
          size={24}
        />

        <p className={cn(typography.platformH3, styles.title)}>
          Loading
          <span className={styles.dots}>...</span>
        </p>
      </div>

      {isLoadingTooLong && (
        <>
          <div className="flex-container align-center gap-8">
            <Button
              data-qa="preloader-reload-button"
              mode={Button.mode.TransparentLight}
              size={Button.size.Small}
              onClick={reload}
              text={t(`${I18N_CODES.common}:content-preloader_reload_button`)}
              LeftIcon={IconReset}
              dataQa='content-preloader-reload-button'
            />
            {feedbackButton}
          </div>
        </>
      )}
    </div>
  );
};
