import { GrowthBook } from '@growthbook/growthbook-react';
import getConfig from 'next/config';
import { AppEnvironments } from '@/lib/constants/general';
import {
  GoogleAnalyticsClient,
} from '@/controllers/analytics/analytics.client/GoogleAnalytics.client';
import { Router } from '@/middleware/i18n';
import {
  setAmplitudeUserProperties,
} from '@/controllers/amplitude/amplitude.helpers';
import { shouldShowGBConsoleMessages } from '@/components/services/GrowthBook/GrowthBook.helpers/GrowthBook.helpers';
import {
  removeQueryParams,
} from '@/controllers/analytics/analytics.utils/removeQueryParams';
import { i18nParseLanguageSubpath } from '@/middleware/i18n/i18n.utils';
import { setClaritySessionTags } from '@/controllers/clarity/clarity.helpers';

export class GrowthBookClient {
    private static instance: GrowthBookClient;

    gb: GrowthBook;

    constructor() {
      this.gb = GrowthBookClient.initGbInstance();
    }

    static getInstance(): GrowthBookClient {
      if (!GrowthBookClient.instance) {
        GrowthBookClient.instance = new GrowthBookClient();
      }

      return GrowthBookClient.instance;
    }

    init() {
      this.loadFeatures();
      this.updateRouteAttributes();
      this.subscribeToRouteChange();
    }

    private loadFeatures() {
      return this.gb.loadFeatures();
    }

    updateUserAttributes() {
      const userId = (
        GoogleAnalyticsClient.getInstance().getAnalyticsUserId()
      );
      const deviceId = (
        GoogleAnalyticsClient.getInstance().getAnalyticsDeviceId()
      );

      const userAttributes = {
        id: userId ?? deviceId ?? '',
        deviceId,
        loggedIn: Boolean(userId),
      };

      this.gb.setAttributes({
        ...this.gb.getAttributes(),
        ...userAttributes,
      });
    }

    private updateRouteAttributes() {
      // eslint-disable-next-line @mate-academy/frontend/restrict-window-usage
      if (typeof window === 'undefined') {
        return;
      }

      const { locale } = Router;

      const subDomain = i18nParseLanguageSubpath(locale).domain;

      this.gb.setAttributes({
        ...this.gb.getAttributes(),
        url: removeQueryParams(Router.asPath),
        locale,
        subDomain,
      });
    }

    private subscribeToRouteChange() {
      Router.events.on('routeChangeComplete', () => {
        this.updateRouteAttributes();
      });
    }

    private static initGbInstance() {
      const { publicRuntimeConfig = {} } = getConfig() || {};

      const shouldShowGBMessages = shouldShowGBConsoleMessages();

      const {
        GROWTHBOOK_API_ENDPOINT,
        GROWTHBOOK_CLIENT_KEY,
        APP_ENV,
      } = publicRuntimeConfig;

      return new GrowthBook({
        apiHost: GROWTHBOOK_API_ENDPOINT,
        clientKey: GROWTHBOOK_CLIENT_KEY,
        enableDevMode: APP_ENV !== AppEnvironments.Production,
        // Update the instance in realtime as features change in GrowthBook
        subscribeToChanges: true,
        trackingCallback: (experiment, result) => {
          if (shouldShowGBMessages) {
            // eslint-disable-next-line no-console
            console.log('🧪🔬 EXPERIMENT 🔬🧪', {
              experimentId: experiment.key,
              variationId: result.key,
            });
          }

          GoogleAnalyticsClient.getInstance().sendEvent('experiment_impression', {
            experimentId: experiment.key,
            variationId: result.key,
          });

          setAmplitudeUserProperties({
            [`gb_exp_${experiment.key}`]: result.key,
          });

          setClaritySessionTags({
            [`gb_exp_${experiment.key}`]: result.key,
          });
        },
      });
    }
}
