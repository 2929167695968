import React, { FC, useCallback, useRef } from 'react';
import { useIsOpenStateDeprecated } from '@/hooks/useIsOpenStateDeprecated';
import { cn } from '@/lib';
import { TooltipContent } from '@/components/ui/ToolTip/TooltipWithPortal/TooltipContent';
import { TooltipWithPortalProps } from '../tooltip.typedefs';
import portalTooltipStyles from './TooltipWithPortal.module.scss';

export const TooltipWithPortal: FC<TooltipWithPortalProps> = (props) => {
  const {
    shouldIncreaseHoverArea = false,
    children,
    className,
    text,
    shouldCloseOnInteraction = false,
    dataQa,
    isVisible = true,
  } = props;

  const parentRef = useRef<HTMLDivElement>(null);

  const [
    isOpen,
    close,
    open,
  ] = useIsOpenStateDeprecated();

  const clickHandler = useCallback(() => {
    if (shouldCloseOnInteraction) {
      close();
    }
  }, [shouldCloseOnInteraction, close]);

  if (!text) {
    return <>{children}</>;
  }

  return (
    <div
      aria-hidden
      ref={parentRef}
      onMouseEnter={open}
      onMouseLeave={close}
      onClick={clickHandler}
      className={cn(
        portalTooltipStyles.parent,
        { [portalTooltipStyles.increasedHoverArea]: shouldIncreaseHoverArea },
        className,
      )}
      data-qa={dataQa}
    >
      {children}

      {isOpen && isVisible && (
        <TooltipContent
          parentElement={parentRef.current}
          {...props}
        />
      )}
    </div>
  );
};
