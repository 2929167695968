import React, {
  DetailedHTMLProps, FC, InputHTMLAttributes, useCallback, useRef, useState,
} from 'react';
import { Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import { Libraries } from '@react-google-maps/api/src/utils/make-load-script-url';
import getConfig from 'next/config';
import { Async } from 'react-select/async';
import { useDI } from '@mate-academy/react-di';
import { UseFieldConfig } from 'react-final-form';
import { NoSSR } from '@/components/common/NoSSR';
import { cn } from '@/lib/classNames';
import { withFinalFormController } from '@/controllers/forms/forms.hocs/withFinalFormController';
import { SelectOptionInterface } from '@/controllers/forms/forms.typedefs';
import formStyles from '@/components/ui/FormElements/FormInputs/FormInputs.module.scss';
import { SelectAsyncUi } from '@/components/ui/FormElements/FormInputs/Select/SelectAsync';
import { nullFunction } from '@/lib/helpers/functional';

export interface GoogleCountryResult {
  placeName: string;
  placeShortName: string;
  placeId: string;
}

export interface GoogleCountrySelectOptions extends DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> {
  handleChange: (place: GoogleCountryResult) => void;
  inputId: string;
  isMulti?: boolean;
  isDisabled?: boolean;
}

const libraries: Libraries = ['places'];

const { publicRuntimeConfig = {} } = getConfig() || {};
const {
  GOOGLE_PLACES_API_KEY,
} = publicRuntimeConfig;

export const CountrySelectorUi: FC<GoogleCountrySelectOptions> = (
  {
    className,
    handleChange,
    inputId,
    isMulti,
    ...rest
  },
) => {
  const inputRef = useRef<Async<SelectOptionInterface> | null>(null);

  const [
    autoCompleteInstance,
    setInstance,
  ] = useState<google.maps.places.Autocomplete | null>(null);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_PLACES_API_KEY,
    language: 'en',
    libraries,
  });

  const onPlaceChanged = useCallback(() => {
    const place = autoCompleteInstance?.getPlace();

    if (place && place.place_id && place.name) {
      const placeAdress = place.address_components?.find(
        (el) => el.short_name,
      );

      const preparedPlace: GoogleCountryResult = {
        placeName: place.name,
        placeShortName: placeAdress?.short_name || '',
        placeId: place.place_id,
      };

      handleChange(preparedPlace);

      if (inputRef && inputRef.current) {
        inputRef.current.blur();
      }
    }
  }, [autoCompleteInstance, handleChange]);

  // ATTENTION: On CI we test mocked component before commit test your changes by running local e2e tests
  // INCIDENT: https://app.clickup.com/24383048/v/dc/q83j8-96295/q83j8-916135

  if (!isLoaded) {
    return null;
  }

  return (
    <Autocomplete
      onLoad={(instance) => {
        setInstance(instance);
      }}
      onPlaceChanged={onPlaceChanged}
      types={['country']}
      fields={['name', 'place_id', 'address_components']}
    >
      <SelectAsyncUi
        {...rest}
        isMulti={isMulti}
        className={cn(formStyles.googleCitySelect, className)}
        noOptionsMessage={nullFunction}
        components={{
          DropdownIndicator: nullFunction,
        }}
        ref={inputRef}
        inputId={inputId}
      />
    </Autocomplete>
  );
};

export const CountrySelector = withFinalFormController<
  SelectOptionInterface
>()<GoogleCountrySelectOptions>(CountrySelectorUi);

type Props = GoogleCountrySelectOptions & {
  name: string;
  id?: string;
  config?: UseFieldConfig<SelectOptionInterface>;
};

export const GoogleSelectorWithDI: FC<Props> = React.memo((props) => {
  const Select = useDI(CountrySelector);

  return (
    <Select
      {...props}
    />
  );
});

export const GoogleCountrySelector: FC<Props> = React.memo((props) => (
  <NoSSR>
    <GoogleSelectorWithDI {...props} />
  </NoSSR>
));
